/*
These are constants for use across lumos tests.
They represent common CGDO formatted data responses that
match with the data setting IDs in
src/app/modules/dataSettings/mocks/mockDataSettingsResponse.ts
*/

import {
  FormattedData,
  FormattedDataCensorshipStatus,
} from 'app/modules/dataSettings/responses';

export const MOCK_ENTITY_FORMATTED_DATA: FormattedData = {
  178: {
    // native_field: 'Account Holder Name',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
    is_parseable: true,
    raw_value: 'John Smith',
    formatted_value: 'John Smith',
  },
  179: {
    // native_field: 'Addresses',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
    is_parseable: true,
    raw_value: ['1000000 Blvd of Broken Dreams'],
    formatted_value: ['1000000 Blvd of Broken Dreams'],
  },
  180: {
    // native_field: 'Business Name',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
    is_parseable: true,
    raw_value: null,
    formatted_value: null,
  },
  181: {
    // native_field: 'Client Fingerprints',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
    is_parseable: true,
    raw_value: ['fingerprint1', 'fingerprint2'],
    formatted_value: ['fingerprint1', 'fingerprint2'],
  },
  182: {
    // native_field: 'Corporate Tax ID',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
    is_parseable: true,
    raw_value: null,
    formatted_value: null,
  },
  183: {
    // native_field: 'Date of Birth',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
    is_parseable: true,
    raw_value: '1900-01-01T00:00:00+00:00',
    formatted_value: '1900-01-01T00:00:00+00:00',
  },
  184: {
    // native_field: 'Doing Business As',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
    is_parseable: true,
    raw_value: '',
    formatted_value: '',
  },
  185: {
    // native_field: 'Email Addresses',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
    is_parseable: true,
    raw_value: ['jsmith@aol.com', 'cchio@unit21.ai'],
    formatted_value: ['jsmith@aol.com', 'cchio@unit21.ai'],
  },
  186: {
    // native_field: 'Entity Subtype',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
    is_parseable: true,
    raw_value: 'Hobgoblin',
    formatted_value: 'Hobgoblin',
  },
  187: {
    // native_field: 'Entity Type',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
    is_parseable: true,
    raw_value: 'USER',
    formatted_value: 'USER',
  },
  188: {
    // native_field: 'Entity ID',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
    is_parseable: true,
    raw_value: 'abc-def-ghi-123',
    formatted_value: 'abc-def-ghi-123',
  },
  189: {
    // native_field: 'First Name',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
    is_parseable: true,
    raw_value: 'John',
    formatted_value: 'John',
  },
  190: {
    // native_field: 'Gender',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
    is_parseable: true,
    raw_value: 'male',
    formatted_value: 'male',
  },
  191: {
    // native_field: 'IP Addresses',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
    is_parseable: true,
    raw_value: null,
    formatted_value: null,
  },
  192: {
    // native_field: 'Last Name',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
    is_parseable: true,
    raw_value: 'Smith',
    formatted_value: 'Smith',
  },
  193: {
    // native_field: 'Middle Name',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
    is_parseable: true,
    raw_value: '',
    formatted_value: '',
  },
  194: {
    // native_field: 'Phone Numbers',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
    is_parseable: true,
    raw_value: ['+13103103100'],
    formatted_value: ['+13103103100'],
  },
  195: {
    // native_field: 'Physical IDs',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
    is_parseable: true,
    raw_value: null,
    formatted_value: null,
  },
  196: {
    // native_field: 'Registered At',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
    is_parseable: true,
    raw_value: '2019-11-02T05:40:26+00:00',
    formatted_value: '2019-11-02T05:40:26+00:00',
  },
  197: {
    // native_field: 'Registered Country',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
    is_parseable: true,
    raw_value: 'USA',
    formatted_value: 'USA',
  },
  198: {
    // native_field: 'Registered State',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
    is_parseable: true,
    raw_value: 'CA',
    formatted_value: 'CA',
  },
  199: {
    // native_field: 'Status',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
    is_parseable: true,
    raw_value: 'ACTIVE',
    formatted_value: 'ACTIVE',
  },
  200: {
    // native_field: 'User Name',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
    is_parseable: true,
    raw_value: 'jsmith.thehobgoblin',
    formatted_value: 'jsmith.thehobgoblin',
  },
  201: {
    // native_field: 'Website',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
    is_parseable: true,
    raw_value: '',
    formatted_value: '',
  },
  1852: {
    // native_field: 'SSN',
    censorship: FormattedDataCensorshipStatus.UNCENSORABLE,
    is_parseable: true,
    raw_value: '000-00-0000',
    formatted_value: '000-00-0000',
  },
  // custom data internal notes
  65: {
    is_parseable: true,
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
    raw_value: 'Reviewed by aaa on 31 June 2019',
    formatted_value: 'Reviewed by aaa on 31 June 2019',
  },
  // json_data_type
  66: {
    is_parseable: true,
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
    raw_value: {
      id: '0001',
      type: 'donut',
      name: 'Cake',
      ppu: 0.55,
      batters: {
        batter: [
          { id: '1001', type: 'Regular' },
          { id: '1002', type: 'Chocolate' },
          { id: '1003', type: 'Blueberry' },
          { id: '1004', type: "Devil's Food" },
        ],
      },
      topping: [
        { id: '5001', type: 'None' },
        { id: '5002', type: 'Glazed' },
        { id: '5005', type: 'Sugar' },
        { id: '5007', type: 'Powdered Sugar' },
        { id: '5006', type: 'Chocolate with Sprinkles' },
        { id: '5003', type: 'Chocolate' },
        { id: '5004', type: 'Maple' },
      ],
    },
    formatted_value: {
      id: '0001',
      type: 'donut',
      name: 'Cake',
      ppu: 0.55,
      batters: {
        batter: [
          { id: '1001', type: 'Regular' },
          { id: '1002', type: 'Chocolate' },
          { id: '1003', type: 'Blueberry' },
          { id: '1004', type: "Devil's Food" },
        ],
      },
      topping: [
        { id: '5001', type: 'None' },
        { id: '5002', type: 'Glazed' },
        { id: '5005', type: 'Sugar' },
        { id: '5007', type: 'Powdered Sugar' },
        { id: '5006', type: 'Chocolate with Sprinkles' },
        { id: '5003', type: 'Chocolate' },
        { id: '5004', type: 'Cherry Blossom' },
      ],
    },
  },
};

export const MOCK_BUSINESS_ENTITY_FORMATTED_DATA: FormattedData = {
  178: {
    // native_field: 'Account Holder Name',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
    is_parseable: true,
    raw_value: 'John Smith',
    formatted_value: 'John Smith',
  },
  179: {
    // native_field: 'Addresses',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
    is_parseable: true,
    raw_value: ['1000000 Blvd of Broken Dreams'],
    formatted_value: ['1000000 Blvd of Broken Dreams'],
  },
  180: {
    // native_field: 'Business Name',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
    is_parseable: true,
    raw_value: 'Global Liquids LLC',
    formatted_value: 'Global Liquids LLC',
  },
  181: {
    // native_field: 'Client Fingerprints',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
    is_parseable: true,
    raw_value: ['fingerprint1', 'fingerprint2'],
    formatted_value: ['fingerprint1', 'fingerprint2'],
  },
  182: {
    // native_field: 'Corporate Tax ID',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
    is_parseable: true,
    raw_value: '434-455-3167',
    formatted_value: '434-455-3167',
  },
  183: {
    // native_field: 'Date of Birth',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
    is_parseable: true,
    raw_value: null,
    formatted_value: null,
  },
  184: {
    // native_field: 'Doing Business As',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
    is_parseable: true,
    raw_value: 'Liquids',
    formatted_value: 'Liquids',
  },
  185: {
    // native_field: 'Email Addresses',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
    is_parseable: true,
    raw_value: ['jsmith@aol.com', 'cchio@unit21.ai'],
    formatted_value: ['jsmith@aol.com', 'cchio@unit21.ai'],
  },
  186: {
    // native_field: 'Entity Subtype',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
    is_parseable: true,
    raw_value: 'Apothecary',
    formatted_value: 'Apothecary',
  },
  187: {
    // native_field: 'Entity Type',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
    is_parseable: true,
    raw_value: 'BUSINESS',
    formatted_value: 'BUSINESS',
  },
  188: {
    // native_field: 'Entity ID',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
    is_parseable: true,
    raw_value: 'abc-def-ghi-123',
    formatted_value: 'abc-def-ghi-123',
  },
  189: {
    // native_field: 'First Name',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
    is_parseable: true,
    raw_value: null,
    formatted_value: null,
  },
  190: {
    // native_field: 'Gender',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
    is_parseable: true,
    raw_value: null,
    formatted_value: null,
  },
  191: {
    // native_field: 'IP Addresses',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
    is_parseable: true,
    raw_value: ['192.135.209.214'],
    formatted_value: ['192.135.209.214'],
  },
  192: {
    // native_field: 'Last Name',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
    is_parseable: true,
    raw_value: null,
    formatted_value: null,
  },
  193: {
    // native_field: 'Middle Name',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
    is_parseable: true,
    raw_value: '',
    formatted_value: '',
  },
  194: {
    // native_field: 'Phone Numbers',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
    is_parseable: true,
    raw_value: ['+13103103100'],
    formatted_value: ['+13103103100'],
  },
  195: {
    // native_field: 'Physical IDs',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
    is_parseable: true,
    raw_value: null,
    formatted_value: null,
  },
  196: {
    // native_field: 'Registered At',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
    is_parseable: true,
    raw_value: '2019-11-02T05:40:26+00:00',
    formatted_value: '2019-11-02T05:40:26+00:00',
  },
  197: {
    // native_field: 'Registered Country',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
    is_parseable: true,
    raw_value: 'USA',
    formatted_value: 'USA',
  },
  198: {
    // native_field: 'Registered State',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
    is_parseable: true,
    raw_value: 'CA',
    formatted_value: 'CA',
  },
  199: {
    // native_field: 'Status',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
    is_parseable: true,
    raw_value: 'ACTIVE',
    formatted_value: 'ACTIVE',
  },
  200: {
    // native_field: 'User Name',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
    is_parseable: true,
    raw_value: null,
    formatted_value: null,
  },
  201: {
    // native_field: 'Website',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
    is_parseable: true,
    raw_value: 'www.myspace.com',
    formatted_value: 'www.myspace.com',
  },
  1852: {
    // native_field: 'SSN',
    censorship: FormattedDataCensorshipStatus.UNCENSORABLE,
    is_parseable: true,
    raw_value: null,
    formatted_value: null,
  },
  // custom data internal notes
  65: {
    is_parseable: true,
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
    raw_value: 'Reviewed by aaa on 31 June 2019',
    formatted_value: 'Reviewed by aaa on 31 June 2019',
  },
  // json_data_type
  66: {
    is_parseable: true,
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
    raw_value: {
      id: '0001',
      type: 'donut',
      name: 'Cake',
      ppu: 0.55,
      batters: {
        batter: [
          { id: '1001', type: 'Regular' },
          { id: '1002', type: 'Chocolate' },
          { id: '1003', type: 'Blueberry' },
          { id: '1004', type: "Devil's Food" },
        ],
      },
      topping: [
        { id: '5001', type: 'None' },
        { id: '5002', type: 'Glazed' },
        { id: '5005', type: 'Sugar' },
        { id: '5007', type: 'Powdered Sugar' },
        { id: '5006', type: 'Chocolate with Sprinkles' },
        { id: '5003', type: 'Chocolate' },
        { id: '5004', type: 'Maple' },
      ],
    },
    formatted_value: {
      id: '0001',
      type: 'donut',
      name: 'Cake',
      ppu: 0.55,
      batters: {
        batter: [
          { id: '1001', type: 'Regular' },
          { id: '1002', type: 'Chocolate' },
          { id: '1003', type: 'Blueberry' },
          { id: '1004', type: "Devil's Food" },
        ],
      },
      topping: [
        { id: '5001', type: 'None' },
        { id: '5002', type: 'Glazed' },
        { id: '5005', type: 'Sugar' },
        { id: '5007', type: 'Powdered Sugar' },
        { id: '5006', type: 'Chocolate with Sprinkles' },
        { id: '5003', type: 'Chocolate' },
        { id: '5004', type: 'Cherry Blossom' },
      ],
    },
  },
};

export const MOCK_INSTRUMENT_FORMATTED_DATA: FormattedData = {
  // Addresses
  144: {
    raw_value: ['Address 1', 'Address 2'],
    is_parseable: true,
    formatted_value: ['Address 1', 'Address 2'],
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
  },
  // Client Fingerprints
  145: {
    raw_value: ['fingerprint1', 'fingerprint2'],
    is_parseable: true,
    formatted_value: ['fingerprint1', 'fingerprint2'],
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
  },
  // Instrument ID
  146: {
    raw_value: 'External ID',
    is_parseable: true,
    formatted_value: 'External ID',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
  },
  // Instrument Subtype
  147: {
    raw_value: 'Subtype',
    is_parseable: true,
    formatted_value: 'Subtype',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
  },
  // Instrument Type
  148: {
    raw_value: 'Type',
    is_parseable: true,
    formatted_value: 'Type',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
  },
  // IP Addresses
  149: {
    raw_value: ['1.1.1.1', '2.2.2.2'],
    is_parseable: true,
    formatted_value: ['1.1.1.1', '2.2.2.2'],
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
  },
  // Name
  150: {
    raw_value: 'Name',
    is_parseable: true,
    formatted_value: 'Name',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
  },
  // Registered At
  151: {
    raw_value: '2023-07-02T00:00:00Z',
    is_parseable: true,
    formatted_value: '2023-07-02T00:00:00Z',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
  },
  // Status
  152: {
    raw_value: 'Status',
    is_parseable: true,
    formatted_value: 'Status',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
  },
  // Source
  1878: {
    raw_value: 'Source',
    is_parseable: true,
    formatted_value: 'Source',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
  },
};
export const MOCK_TXN_EVENT_FORMATTED_DATA: FormattedData = {
  // Amount
  154: {
    raw_value: 69420,
    is_parseable: true,
    formatted_value: 69420,
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
  },
  // Amount
  158: {
    raw_value: 69420,
    is_parseable: true,
    formatted_value: 69420,
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
  },
  // Sent amount
  159: {
    raw_value: 69,
    is_parseable: true,
    formatted_value: 69,
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
  },
  // Received amount
  160: {
    raw_value: 420,
    is_parseable: true,
    formatted_value: 420,
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
  },
  // Timestamp
  157: {
    raw_value: '2019-11-02T23:40:26+00:00',
    is_parseable: true,
    formatted_value: '2019-11-02T23:40:26+00:00',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
  },
  // Internal txn type
  168: {
    raw_value: 'INTERNAL_TYPE',
    is_parseable: true,
    formatted_value: 'INTERNAL_TYPE',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
  },
  2091: {
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
    formatted_value: {
      external_id: 'userB-ef3c5d41-6bc4-4071-b6fd-7dd77c80a5e8',
      flow: 'OUTBOUND',
      formatted_data: {},
      name_readable: 'John Smith',
      unit21_id: '2',
    },
    is_parseable: true,
    raw_value: {},
  },
  2092: {
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
    formatted_value: {
      external_id: 'instrumentB-ef3c5d41-6bc4-4071-b6fd-7dd77c80a5e8',
      flow: 'OUTBOUND',
      formatted_data: {},
      unit21_id: '5',
    },
    is_parseable: true,
    raw_value: {},
  },
  2093: {
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
    formatted_value: {
      external_id: 'userA',
      flow: 'INBOUND',
      formatted_data: {},
      name_readable: 'Barack Obama',
      unit21_id: '8',
    },
    is_parseable: true,
    raw_value: {},
  },
  2094: {
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
    formatted_value: {
      external_id: 'instrumentA-ef3c5d41-6bc4-4071-b6fd-7dd77c80a5e8',
      flow: 'INBOUND',
      formatted_data: {},
      unit21_id: '11',
    },
    is_parseable: true,
    raw_value: {},
  },
};

export const MOCK_ACTION_EVENT_FORMATTED_DATA: FormattedData = {
  // Action type
  171: {
    raw_value: 'Type',
    is_parseable: true,
    formatted_value: 'Type',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
  },
  // Timestamp (event time)
  175: {
    raw_value: '2022-12-28T17:40:57+00:00',
    is_parseable: true,
    formatted_value: '2022-12-28T17:40:57+00:00',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
  },
  // Status
  177: {
    raw_value: 'Action event status',
    is_parseable: true,
    formatted_value: 'Action event status',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
  },
  // internal notes
  300: {
    raw_value: 'Reviewed by Tara on 31 May 2019',
    is_parseable: true,
    formatted_value: 'Reviewed by Tara on 31 May 2019',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
  },
  // external notes
  301: {
    raw_value: '',
    is_parseable: true,
    formatted_value: '',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
  },
  302: {
    raw_value: '123.456.789.10',
    is_parseable: true,
    formatted_value: '123.456.789.10',
    censorship: FormattedDataCensorshipStatus.UNCENSORED,
  },
};

export const MOCK_ALERT_FORMATTED_DATA: FormattedData = {};
